import {
  AppleSignInParams,
  CreateUserParams,
  ISignInError,
  ISignInSuccess,
  SocialSignInParams,
  UserCreateErrorResponse,
  UserCreateSuccessResponse,
  GetUserMetadataParams,
  GetUserMetadataSuccessResponse,
  IRefreshTokenSuccess,
} from '@stryd/models';
import {ClientConfig, HttpClient} from '../../http';
import {toURLParams} from '@stryd/util-lib/urls/toURLParams';

export const setupAuthEndpoints = (client: HttpClient) => {
  return {
    signIn: (email: string, password: string, configs?: ClientConfig) => {
      return client.post<ISignInSuccess, {message: string}>(
        `/b/email/signin`,
        {email, password},
        configs
      );
    },

    signInWithSocial: (params: SocialSignInParams, config?: ClientConfig) => {
      return client.post<ISignInSuccess, ISignInError>(
        `/b/user/signin`,
        params,
        config
      );
    },

    signInWithApple: (params: AppleSignInParams, config?: ClientConfig) => {
      const queryStr = toURLParams(params);
      const url = `/b/user/signin/apple` + '?' + queryStr;
      return client.get<ISignInSuccess, string>(url, config);
    },

    redirectDiscourse: (
      params: {sso: string; sig: string},
      configs?: ClientConfig
    ) => {
      return client.post<{redirect_url: string}, string>(
        `/b/internal/discourse/redirect`,
        params,
        configs
      );
    },

    // TODO this is living alongside the signIn method because it is not in the same router as
    // the other user API endpoints. It should move when we refactor this library
    // to accommodate Canyon routes and split into the same router names as the backend.
    createUser: (params: CreateUserParams, config?: ClientConfig) => {
      return client.post<UserCreateSuccessResponse, UserCreateErrorResponse>(
        `/b/users`,
        params,
        config
      );
    },

    // Currently used to check if a user is existed by email or username.
    getUserMetaData: (params: GetUserMetadataParams, config?: ClientConfig) => {
      const queryStr = toURLParams({...params, metadata: 'count'});
      const url = `/b/stats/user` + '?' + queryStr;
      return client.get<GetUserMetadataSuccessResponse, string>(url, config);
    },

    refreshToken: (
      params: {refreshToken: string; clientId: string},
      config?: ClientConfig
    ) => {
      return client.post<IRefreshTokenSuccess, string>(
        `/b/token/refresh`,
        {refresh_token: params.refreshToken},
        {...config, headers: {...config?.headers, 'Client-ID': params.clientId}}
      );
    },
  };
};
